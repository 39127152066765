define("splittypie/components/quick-add-form", ["exports", "ember-cp-validations", "splittypie/utils/parse-transaction"], function (_exports, _emberCpValidations, _parseTransaction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var Validations = (0, _emberCpValidations.buildValidations)({
    transactionToParse: {
      validators: [(0, _emberCpValidations.validator)("presence", true), (0, _emberCpValidations.validator)("length", {
        max: 200
      })]
    },
    "transaction.name": {
      validators: [(0, _emberCpValidations.validator)("presence", true), (0, _emberCpValidations.validator)("length", {
        max: 50
      })]
    },
    "transaction.amount": {
      validators: [(0, _emberCpValidations.validator)("presence", true), (0, _emberCpValidations.validator)("number", {
        allowString: true
      })]
    }
  });

  var _default = Ember.Component.extend(Validations, {
    userContext: Ember.inject.service(),
    currency: Ember.computed.oneWay("event.currency"),
    event: Ember.computed.oneWay("payer.event"),
    invalidAmount: Ember.computed.not("validations.attrs.transaction.amount.isValid"),
    invalidName: Ember.computed.not("validations.attrs.transaction.name.isValid"),
    isFormInvalid: Ember.computed.not("validations.isValid"),
    participants: Ember.computed.oneWay("event.users"),
    payer: Ember.computed.oneWay("userContext.currentUser"),
    transactionToParse: null,
    transaction: Ember.computed("transactionToParse", function () {
      var event = Ember.get(this, "event");
      var transactionToParse = Ember.get(this, "transactionToParse");
      var transactionProps = (0, _parseTransaction.default)(transactionToParse);
      var payer = Ember.get(this, "payer");
      var participants = Ember.get(this, "participants");

      if (transactionProps && Ember.get(transactionProps, "onlyMe")) {
        participants = [payer];
      }

      return Ember.Object.create(_objectSpread(_objectSpread({}, transactionProps), {}, {
        event: event,
        payer: payer,
        participants: participants
      }));
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      // timeout workaround - wait until modal show animation is over
      setTimeout(function () {
        _this.$(".transaction-parse").focus();
      }, 500);
    },
    actions: {
      add: function add() {
        var isFormInvalid = Ember.get(this, "isFormInvalid");
        var transactionProps = Ember.get(this, "transaction");
        var onAdd = Ember.get(this, "onAdd");
        if (isFormInvalid) return;
        if (typeof onAdd !== "function") return;
        onAdd(transactionProps);
      },
      addWithDetails: function addWithDetails() {
        var onAddWithDetails = Ember.get(this, "onAddWithDetails");
        var transactionProps = Ember.get(this, "transaction");
        if (typeof onAddWithDetails !== "function") return;
        onAddWithDetails(transactionProps);
      }
    }
  });

  _exports.default = _default;
});