define("splittypie/models/event", ["exports", "ember-data", "splittypie/mixins/model-mixin"], function (_exports, _emberData, _modelMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;

  var _default = Model.extend(_modelMixin.default, {
    name: attr("string"),
    isOffline: attr("boolean"),
    currency: belongsTo("currency", {
      async: true
    }),
    users: hasMany("user", {
      async: false
    }),
    transactions: hasMany("transaction", {
      async: false
    }),
    url: Ember.computed("id", function () {
      return "https://split.wuyue98.cn/".concat(Ember.get(this, "id"));
    })
  });

  _exports.default = _default;
});