define("splittypie/initializers/forms", ["exports", "splittypie/forms/event", "splittypie/forms/user", "splittypie/forms/expense", "splittypie/forms/transfer"], function (_exports, _event, _user, _expense, _transfer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize(application) {
    application.register("forms:event", _event.default, {
      instantiate: false
    });
    application.register("forms:user", _user.default, {
      instantiate: false
    });
    application.register("forms:expense", _expense.default, {
      instantiate: false
    });
    application.register("forms:transfer", _transfer.default, {
      instantiate: false
    }); // inject form factory to routes

    application.inject("route", "formFactory", "service:form-factory");
  }

  var _default = {
    name: "forms",
    initialize: initialize
  };
  _exports.default = _default;
});