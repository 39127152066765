define("splittypie/models/user", ["exports", "ember-data", "splittypie/mixins/model-mixin"], function (_exports, _emberData, _modelMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend(_modelMixin.default, {
    name: attr("string"),
    event: belongsTo("event", {
      async: false
    }),
    balance: Ember.computed("event.transactions.[]", function () {
      var _this = this;

      var transactions = Ember.get(this, "event.transactions");
      var paidTransactions = transactions.filterBy("payer", this);
      var owedTransactions = transactions.filter(function (t) {
        return Ember.get(t, "participants").includes(_this);
      });
      var paidMoney = paidTransactions.reduce(function (acc, t) {
        return acc + parseFloat(Ember.get(t, "amount"));
      }, 0);
      var owedMoney = owedTransactions.reduce(function (acc, t) {
        return acc + parseFloat(Ember.get(t, "amount")) / Ember.get(t, "participants").length;
      }, 0);
      return (paidMoney - owedMoney).toFixed(2);
    })
  });

  _exports.default = _default;
});