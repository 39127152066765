define("splittypie/services/syncer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var debug = Ember.Logger.debug;

  var _default = Ember.Service.extend(Ember.Evented, {
    // Events
    // syncStarted: synchronization started
    // syncCompleted: synchronization completed
    // conflict: conflict found
    store: Ember.inject.service(),
    onlineStore: Ember.inject.service(),
    connection: Ember.inject.service(),
    syncQueue: Ember.inject.service(),
    eventListeners: null,
    isOnline: Ember.computed.alias("connection.isOnline"),
    isOnlineStateDidChange: Ember.on("init", Ember.observer("isOnline", function () {
      var isOnline = Ember.get(this, "isOnline");

      if (isOnline) {
        this.syncOnline();
      } else {
        this._removeAllListeners();
      }
    })),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, "eventListeners", Ember.Object.create({}));
    },
    syncOnline: function syncOnline() {
      var _this = this;

      debug("Syncer: Starting online full sync");
      Ember.set(this, "isSyncing", true);
      this.trigger("syncStarted");
      return this._reloadOnlineStore().then(this._flushSyncQueue.bind(this)).then(this._updateOfflineStore.bind(this)).finally(function () {
        debug("Syncer: Full sync has been completed");
        Ember.set(_this, "isSyncing", false);

        _this.trigger("syncCompleted");
      });
    },
    pushEventOffline: function pushEventOffline(onlineEvent) {
      debug("Syncer: Syncing online event ".concat(Ember.get(onlineEvent, "name"), " into offline store"));
      return this._pushToStore(Ember.get(this, "store"), onlineEvent);
    },
    pushEventOnline: function pushEventOnline(offlineEvent) {
      var _this2 = this;

      return this._pushToStore(Ember.get(this, "onlineStore"), offlineEvent).then(function (onlineEvent) {
        Ember.set(offlineEvent, "isOffline", false);

        _this2._listenForChanges(onlineEvent);

        return offlineEvent.save();
      });
    },
    _reloadOnlineStore: function _reloadOnlineStore() {
      var _this3 = this;

      Ember.get(this, "onlineStore").unloadAll();

      this._removeAllListeners();

      return Ember.get(this, "store").findAll("event").then(function (events) {
        return events.map(_this3._fetchOnlineEvent.bind(_this3));
      }).then(function (fetchOperations) {
        return Ember.RSVP.allSettled(fetchOperations);
      });
    },
    _flushSyncQueue: function _flushSyncQueue() {
      return Ember.get(this, "syncQueue").flush();
    },
    _updateOfflineStore: function _updateOfflineStore() {
      var _this4 = this;

      debug("Syncer: Updating Offline Store");
      return Ember.get(this, "store").findAll("event").then(function (events) {
        return events.rejectBy("isOffline", true);
      }).then(function (events) {
        return events.map(_this4._replaceOfflineEvent.bind(_this4));
      }).then(function (operations) {
        return Ember.RSVP.allSettled(operations);
      });
    },
    _fetchOnlineEvent: function _fetchOnlineEvent(offlineEvent) {
      var _Ember$getProperties = Ember.getProperties(offlineEvent, "id", "isOffline"),
          id = _Ember$getProperties.id,
          isOffline = _Ember$getProperties.isOffline;

      if (isOffline) {
        return Ember.RSVP.resolve();
      }

      this._unloadOnlineEvent(id);

      return Ember.get(this, "onlineStore").findRecord("event", id).catch(this._onlineEventNotFound.bind(this, offlineEvent));
    },
    _replaceOfflineEvent: function _replaceOfflineEvent(offlineEvent) {
      var _this5 = this;

      return Ember.get(this, "onlineStore").findRecord("event", Ember.get(offlineEvent, "id")).then(function (onlineEvent) {
        _this5.pushEventOffline(onlineEvent);

        _this5._listenForChanges(onlineEvent);
      });
    },
    _syncOneEvent: function _syncOneEvent(offlineEvent) {
      var _this6 = this;

      this._fetchOnlineEvent(offlineEvent).then(function () {
        _this6._replaceOfflineEvent(offlineEvent);
      }).catch(function () {});
    },
    _onlineEventNotFound: function _onlineEventNotFound(offlineEvent, error) {
      var _Ember$getProperties2 = Ember.getProperties(offlineEvent, "id", "name"),
          id = _Ember$getProperties2.id,
          name = _Ember$getProperties2.name;

      debug("Syncer: Event ".concat(name, " not found online"));
      debug("Syncer: Setting event as offline - it will be available to manual sync");
      Ember.set(offlineEvent, "isOffline", true);

      this._removeListenerFor(id);

      this.trigger("conflict", {
        modelName: "event",
        type: "not-found-online",
        model: {
          id: id,
          name: name
        }
      });
      return offlineEvent.save().then(function () {
        return Ember.RSVP.reject(error);
      });
    },
    _pushToStore: function _pushToStore(store, model) {
      var normalized = this._normalizeModel(model);

      return store.push(normalized).save();
    },
    _normalizeModel: function _normalizeModel(model) {
      var store = Ember.get(this, "store");

      var snapshot = model._createSnapshot();

      var serializer = store.serializerFor(snapshot.modelName);
      var serialized = serializer.serialize(snapshot, {
        includeId: true
      });
      return store.normalize(snapshot.modelName, serialized);
    },
    _unloadOnlineEvent: function _unloadOnlineEvent(id) {
      var event = Ember.get(this, "onlineStore").peekRecord("event", id);

      if (event) {
        Ember.get(this, "onlineStore").unloadRecord(event);

        this._removeListenerFor(id);
      }
    },
    // workaround to keep firebase realtime function
    _listenForChanges: function _listenForChanges(onlineEvent) {
      var _this7 = this;

      var eventListeners = Ember.get(this, "eventListeners");
      var eventId = Ember.get(onlineEvent, "id");
      var isInitial = true;

      if (!eventListeners[eventId]) {
        var ref = onlineEvent.ref();
        onlineEvent.ref().on("value", function (snapshot) {
          Ember.run(function () {
            // don't listen for initial on value
            if (isInitial) {
              isInitial = false;
              return;
            }

            if (Ember.get(_this7, "isSyncing") || Ember.get(_this7, "syncQueue.isProcessing")) {
              return;
            }

            var onlineEventId = snapshot.key; // some changes in firebase not coming from this application instance
            // schedule sync

            Ember.run.scheduleOnce("actions", function () {
              Ember.get(_this7, "store").findRecord("event", onlineEventId).then(_this7._syncOneEvent.bind(_this7));
            });
          });
        });
        eventListeners[eventId] = ref;
        Ember.set(this, "eventListeners", eventListeners);
      }
    },
    _removeAllListeners: function _removeAllListeners() {
      Object.keys(Ember.get(this, "eventListeners")).forEach(this._removeListenerFor.bind(this));
    },
    _removeListenerFor: function _removeListenerFor(eventId) {
      var eventListeners = Ember.get(this, "eventListeners");
      var ref = eventListeners[eventId];

      if (ref) {
        ref.off("value");
        delete eventListeners[eventId];
      }
    }
  });

  _exports.default = _default;
});