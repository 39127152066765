define("splittypie/models/transaction", ["exports", "ember-data", "splittypie/mixins/model-mixin"], function (_exports, _emberData, _modelMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;

  var _default = Model.extend(_modelMixin.default, {
    name: attr("string"),
    amount: attr("number"),
    date: attr("string"),
    event: belongsTo("event", {
      async: false
    }),
    payer: belongsTo("user", {
      async: false
    }),
    participants: hasMany("user", {
      async: false
    }),
    type: attr("string", {
      defaultValue: "expense"
    }),
    typeOrDefault: Ember.computed("type", {
      // FIXME: I don't like this typeOrDefault
      get: function get() {
        return Ember.get(this, "type") || "expense";
      }
    }),
    month: Ember.computed("date", function () {
      var date = Ember.get(this, "date");

      if (date) {
        return date.substring(0, 7);
      }

      return null;
    }),
    isTransfer: Ember.computed.equal("type", "transfer")
  });

  _exports.default = _default;
});