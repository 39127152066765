define("splittypie/initializers/repositories", ["exports", "splittypie/repositories/event", "splittypie/repositories/transaction"], function (_exports, _event, _transaction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize(application) {
    application.register("service:event-repository", _event.default);
    application.register("service:transaction-repository", _transaction.default);
  }

  var _default = {
    name: "repositories",
    initialize: initialize
  };
  _exports.default = _default;
});