define("splittypie/models/currency", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var Currency = Model.extend({
    code: Ember.computed.alias("id"),
    symbol: attr("string"),
    name: attr("string"),
    nameWithCode: Ember.computed("code", "name", function () {
      return "".concat(Ember.get(this, "name"), " (").concat(Ember.get(this, "code"), ")");
    })
  });
  Currency.reopenClass({
    FIXTURES: [{
      id: "USD",
      symbol: "$",
      name: "United States dollar"
    }, {
      id: "EUR",
      symbol: "€",
      name: "Euro"
    }, {
      id: "GBP",
      symbol: "£",
      name: "Pound sterling"
    }, {
      id: "PLN",
      symbol: "zł",
      name: "Polish złoty"
    }, {
      id: "CHF",
      symbol: "CHF",
      name: "Swiss franc"
    }, {
      id: "CZK",
      symbol: "Kč",
      name: "Czech koruna"
    }, {
      id: "HRK",
      symbol: "kn",
      name: "Croatian kuna"
    }, {
      id: "RON",
      symbol: "RON",
      name: "Romanian leu"
    }, {
      id: "BGN",
      symbol: "лв.",
      name: "Bulgarian lev"
    }, {
      id: "RUB",
      symbol: "руб.",
      name: "Russian ruble"
    }]
  });
  var _default = Currency;
  _exports.default = _default;
});