define("splittypie/routes/event", ["exports", "splittypie/utils/is-mobile"], function (_exports, _isMobile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend({
    connection: Ember.inject.service(),
    eventRepository: Ember.inject.service(),
    localStorage: Ember.inject.service(),
    modal: Ember.inject.service(),
    notify: Ember.inject.service(),
    syncer: Ember.inject.service(),
    transactionRepository: Ember.inject.service(),
    userContext: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      var syncer = Ember.get(this, "syncer");

      if (syncer.get("isSyncing")) {
        this._onSyncStarted();
      }

      syncer.on("syncStarted", this._onSyncStarted.bind(this));
      syncer.on("syncCompleted", this._onSyncCompleted.bind(this));
    },
    model: function model(params) {
      return Ember.get(this, "eventRepository").find(params.event_id);
    },
    afterModel: function afterModel(model) {
      var storage = Ember.get(this, "localStorage");
      storage.setItem("lastEventId", model.id);
    },
    redirect: function redirect(model) {
      if (model.constructor.modelName !== "event") return;
      var currentUser = Ember.get(this, "userContext").load(model);

      if (!currentUser) {
        this.transitionTo("event.who-are-you");
      }
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      var events = this.modelFor("application").previousEvents;
      Ember.setProperties(controller, {
        events: events,
        isMobile: (0, _isMobile.default)()
      });
    },
    actions: {
      share: function share() {
        var event = this.modelFor("event");
        Ember.get(this, "modal").trigger("show", {
          name: "share",
          event: event
        });
      },
      saveChanges: function saveChanges() {
        var event = this.modelFor("event");
        event.save();
      },
      switchUser: function switchUser(user) {
        var event = this.modelFor("event");
        Ember.get(this, "userContext").change(event, user);
        Ember.get(this, "notify").success("Now you are watching this event as ".concat(user.get("name")));
      },
      showQuickAdd: function showQuickAdd() {
        Ember.get(this, "modal").trigger("show", {
          name: "quickAdd"
        });
      },
      quickAdd: function quickAdd(transactionProps) {
        var _this = this;

        var event = this.modelFor("event");
        var repository = Ember.get(this, "transactionRepository");
        var store = Ember.get(this, "store");
        var transaction = store.createRecord("transaction", _objectSpread({}, transactionProps));
        repository.save(event, transaction).then(function () {
          Ember.get(_this, "notify").success("Transaction has been saved.");
        });
      },
      quickAddWithDetails: function quickAddWithDetails(transactionProps) {
        this.transitionTo("event.transactions.new", {
          queryParams: Ember.getProperties(transactionProps, "amount", "date", "name")
        });
      },
      error: function error(_error, transition) {
        var eventId = transition.params.event.event_id;
        var storage = Ember.get(this, "localStorage");
        var lastEventId = storage.getItem("lastEventId"); // FIXME: Do this better

        if (_error && _error.message && _error.message.indexOf("no record was found") > -1 && eventId === lastEventId) {
          storage.removeItem("lastEventId");
          this.transitionTo("index");
        } else {
          return true;
        }

        return false;
      }
    },
    _onSyncStarted: function _onSyncStarted() {
      var syncProgressIndicator = new window.Mprogress({
        template: 3
      });
      syncProgressIndicator.start();
      Ember.set(this, "syncProgressIndicator", syncProgressIndicator);
    },
    _onSyncCompleted: function _onSyncCompleted() {
      var syncProgressIndicator = Ember.get(this, "syncProgressIndicator");

      if (syncProgressIndicator) {
        syncProgressIndicator.end();
      }
    }
  });

  _exports.default = _default;
});