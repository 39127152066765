define("splittypie/initializers/rollbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function shouldReportError(error) {
    if (error.message && error.message.indexOf("no record was found") > -1) {
      return false;
    }

    if (error.name === "TransitionAborted") {
      return false;
    }

    return true;
  }

  function reportError(error) {
    if (Ember.testing) {
      throw error;
    }

    if (shouldReportError(error)) {
      if (error.stack) {
        console.error(error);
        console.error(error.stack);
      }

      if (window.Rollbar) {
        window.Rollbar.error(error);
      }
    }

    return true;
  }

  function initialize(application) {
    var syncQueue = application.__container__.lookup("service:syncQueue");

    syncQueue.on("error", reportError);
    Ember.onerror = reportError;
    window.onerror = reportError;
    Ember.RSVP.on("error", reportError);
  }

  var _default = {
    name: "rollbar",
    initialize: initialize
  };
  _exports.default = _default;
});